import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { ContentBlock } from "../../../components/Blocks";
import Contents from "../../../components/Contents";
import Pages from "../../../components/Pages";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { makeUrl, removePreloader } from "../../../helpers";
import { Share } from "../../../widgets";

export const query = graphql`
	query media_booksDataQuery($slug: String!) {
		hasura {
			media_books(where: { slug: { _eq: $slug } }) {
				title_full
				page_title_full
				page_title_short
				slug
				main_image
				main_image_preview
				seo
				content_blocks
			}
		}
	}
`;
const FETCH_QUERY = gql`
	query FetchData($location: String!) {
		media_books(where: { slug: { _eq: $location } }) {
			title_full
			page_title_full
			page_title_short
			slug
			main_image
			main_image_preview
			seo
			content_blocks
		}
	}
`;

export default function MediaBooksPage({ location, data }) {
	const hasuraData = data.hasura;
	const [path] = useState(last(compact(location.pathname.split("/"))));
	const [isMount, setIsMount] = useState(false);
	const [books, setBooks] = useState(get(hasuraData, "media_books[0]", {}));

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount) {
			setBooks(get(fetchData, "media_books[0]", {}));
		}
		if (error) {
			setBooks(get(hasuraData, "media_books[0]", {}));
		}

		removePreloader(!loading || error);
	}, [loading]);

	const { slug, page_title_full, page_title_short, main_image_preview, main_image, content_blocks } = books;

	const url = makeUrl.mediaBook({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	return (
		<Pages entity={books} url={url} >
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={page_title_full} />
				</div>
			</ContentBlock>
			<ContentBlock
				key={"media-content"}
				mount={content_blocks && !!content_blocks.length}
				className={"text-page"}
			>
				<div className={"container"}>
					<div className={"h1 text-page_title"}>
						<h1 itemProp="headline">{page_title_full}</h1>
					</div>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share
					url={url}
					pageTitleShort={page_title_short}
					pageTitleFull={page_title_full}
					imageSrc={imageSrc}
				/>
			</div>
		</Pages>
	);
}

MediaBooksPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

MediaBooksPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
